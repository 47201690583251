angular
  .module('starter.business.controllers')
  .controller('rostersCtrlV2', function ($scope, $rootScope, $window, $ngRedux) {
    $scope.user = {}
    $scope.company = {}
    $scope.iFrameStyle = $rootScope.isOverlayVisible ? 'w-full h-full relative z-modal' : 'w-full h-full relative'
    $rootScope.$watch('isOverlayVisible', () => {
      $scope.iFrameStyle = $rootScope.isOverlayVisible ? 'w-full h-full relative z-modal' : 'w-full h-full relative'
    })
    $ngRedux.connect((state) => state.app.user || {})($scope.user)
    $ngRedux.connect((state) => state.app.company || {})($scope.company)
    console.log('Running rostersCtrlV2')
    $scope.isLoading = true
    $scope.token = $window.localStorage.pr_token
    const agentId = $window.localStorage.pr_agent_id
    $scope.$watchGroup(['user.e', 'company.e'], () => {
      if (!$scope.user.e && !$scope.company.e) return
      const generateIframeSrc = () => {
        const e = $scope.user?.e || $scope.company?.e
        const clientId = localStorage.pr_client_id || JSON.parse(localStorage.pr_company)._id
        return `${process.env.DEV_V2}/business/roster?isIframe=true&t=${$scope.token}&e=${e}&userType=${
          agentId ? 'agent' : 'employer'
        }&clientId=${clientId}`
      }
      const peopleRevampIframe = document.getElementById('roster-v2')
      peopleRevampIframe.src = generateIframeSrc()
      peopleRevampIframe.onload = () => {
        $scope.isLoading = false
        $rootScope.$apply()
      }
    })
  })

import { createSlice } from '@reduxjs/toolkit'
import {
  updateSubscription,
  getSubscriptionStatus,
  getInitial,
  getInitialByYear,
  agentInit,
  setModalSubscription
} from '@payroller/frontend/src/scheduling/react-subscription/utils/share'
import { createSelector } from 'reselect'
import { paywallApi } from '../api/paywall'
import { filterActiveEmployees } from '../utils/filterEmployees'
import { getFiscalYear, getFiscalYears, getLatestFiscalYear } from '../utils/getFiscalYear'
import isPrerenderForV2 from '../scheduling/constant/isPrerenderForV2'

function initDataHandler(state, data) {
  let payload = _.cloneDeep(data)
  state.user = payload.user
  state.company = payload.company
  state.employees = payload.employees
  state.payRuns = payload.payRuns
  state.leaves = payload.leaves
  state.holidays = payload.holidays
  state.finalAdjustments = payload.finalAdjustments
  state.subscriptionStatus = payload.subscriptionStatus
  state.taxYear = getLatestFiscalYear(payload.payRuns)
  state.subscriptionStatus = payload.subscriptionStatus
  if (payload.allFinancialYears) state.allFinancialYears = payload.allFinancialYears
  if (payload.selectedTaxYear) state.selectedTaxYear = payload.selectedTaxYear
  if (payload?.employeeData) state.employeeData = payload.employeeData

  if (payload?.financialYear) state.FYLoad = [{ fy: Number(payload.financialYear) - 1, isLoaded: true }]
  else {
    state.FYLoad = (payload.allFinancialYears || []).map((i) => {
      return { fy: i - 1, isLoaded: true }
    })
  }
}

function initDataHandlerByYear(state, data) {
  let payload = _.cloneDeep(data)
  if (payload?.payRuns) state.payRuns = [...state.payRuns, ...payload.payRuns]

  state.FYLoad = [...state.FYLoad, { fy: Number(payload.financialYear) - 1, isLoaded: true }]
}

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isAgent: false,
    agent: null,
    modals: [],
    alerts: [],
    gbFeatures: {},
    pdfModal: null,
    isNewPayrun: false,
    taxYear: null,
    showHelpMenu: !isPrerenderForV2 ? window.innerWidth > 1400 : false,
    isTsrOnly: process.env.APP === 'people',
    isKeyboardVisible: false,
    shouldShowAgentProgramBanner: false,
    subscriptionStatus: {},
    FYLoad: [],
    isPayrunsLoading: false
  },
  reducers: {
    setInitialState: (state, action) => {
      initDataHandler(state, action.payload)
    },
    resetInitialState: (state) => {
      state.user = {}
      state.company = {}
      state.employees = []
      state.payRuns = []
      state.leaves = []
      state.allFinancialYears = []
      state.holidays = []
      state.holidays = []
      state.subscriptionStatus = {}
      state.employeeData = {}
      state.FYLoad = []
    },
    setEmployeesSuperRate: (state, action) => {
      state.employees.forEach((e) => {
        // only update people lower than the mandatory super rate
        if (!e.s_r || e.s_r < action.payload) {
          e.s_r = action.payload
        }
      })
    },
    setOverrideSuper: (state, action) => {
      state.overrideSuper = action.payload
    },
    setDoNotRemindMe: (state, action) => {
      state.company.superIncrementStatus = {
        ...state.company.superIncrementStatus,
        doNotRemindMe: action.payload
      }
    },
    setUpdateMySuper: (state, action) => {
      state.company.superIncrementStatus = {
        ...state.company.superIncrementStatus,
        updateMySuper: action.payload
      }
    },
    setEmployees: (state, action) => {
      state.employees = _.cloneDeep(action.payload)
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setIsAgent: (state, action) => {
      state.isAgent = action.payload
    },
    setCompany: (state, action) => {
      state.company = action.payload
    },
    setSubscriptionStatus: (state, action) => {
      state.subscriptionStatus = action.payload
    },
    setAgent: (state, action) => {
      state.agent = _.cloneDeep(action.payload)
    },
    setModals: (state, action) => {
      state.modals = action.payload
    },
    setPdfModal: (state, action) => {
      state.pdfModal = action.payload
    },
    setAlerts: (state, action) => {
      state.alerts = action.payload
    },
    setAlert: (state, action) => {
      state.alerts = [...state.alerts, { ...action.payload, id: `${Math.random()}`.replace('.', '') }]
    },
    setPayRuns: (state, action) => {
      state.payRuns = _.cloneDeep(action.payload)
      state.taxYear = getLatestFiscalYear(action.payload)
    },
    setTaxYear: (state, action) => {
      state.taxYear = action.payload
    },
    setSelectedTaxYear: (state, action) => {
      state.selectedTaxYear = action.payload
    },
    setGbFeatures: (state, action) => {
      state.gbFeatures = action.payload
    },
    setIsNewPayrun: (state, action) => {
      state.isNewPayrun = action.payload
    },
    setShowHelpMenu: (state, action) => {
      state.showHelpMenu = action.payload
    },
    setIsTsrOnly: (state, action) => {
      state.isTsrOnly = action.payload
    },
    setIsKeyboardVisible: (state, action) => {
      state.isKeyboardVisible = action.payload
    },
    showAgentBanner: (state) => {
      state.shouldShowAgentProgramBanner = true
    },
    hideAgentBanner: (state) => {
      state.shouldShowAgentProgramBanner = false
    },
    setFYLoad: (state, action) => {
      state.FYLoad = [...state.FYLoad, { ...action.payload }]
    },
    setIsPayrunsLoading: (state, action) => {
      state.isPayrunsLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitial.fulfilled, (state, action) => {
        initDataHandler(state, action.payload)
      })
      .addCase(getInitialByYear.fulfilled, (state, action) => {
        initDataHandlerByYear(state, action.payload)
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.subscriptionStatus.webapp = {
          isValid: action.payload.subscription[0],
          ...action.payload.subscription[1]
        }
      })
      .addCase(getSubscriptionStatus.fulfilled, (state, action) => {
        state.subscriptionStatus = action.payload.subscriptionStatus
      })
      .addCase(agentInit.fulfilled, (state, action) => {
        state.agent = _.cloneDeep(action.payload.agent)
      })
      // to show the new_paywall modal
      .addCase(setModalSubscription.fulfilled, (state, action) => {
        const gbFeatures = JSON.parse(JSON.stringify(state)).gbFeatures

        if (gbFeatures.new_paywall) {
          const payload = [{ kind: 'Paywall', options: { overlay: true } }]
          state.modals = payload
        }
      })
      .addMatcher(paywallApi.endpoints.subscribe.matchFulfilled, (state, action) => {
        if (!action.payload) {
          return
        }
        state.subscriptionStatus.webapp = {
          isValid: action.payload.subscription[0],
          ...action.payload.subscription[1]
        }
      })
  }
})

export const {
  setInitialState,
  resetInitialState,
  setEmployeesSuperRate,
  setOverrideSuper,
  setDoNotRemindMe,
  setUpdateMySuper,
  setUser,
  setIsAgent,
  setEmployees,
  setCompany,
  setPayRuns,
  setTaxYear,
  setSelectedTaxYear,
  setSubscriptionStatus,
  setAgent,
  setModals,
  setPdfModal,
  setAlerts,
  setAlert,
  setGbFeatures,
  setIsNewPayrun,
  setShowHelpMenu,
  setIsTsrOnly,
  setIsKeyboardVisible,
  showAgentBanner,
  hideAgentBanner,
  hideAgentPriceLockInBanner,
  setFYLoad,
  setIsPayrunsLoading
} = appSlice.actions
export const stateApp = (state) => state.app
export const activeEmployees = createSelector(
  (state) => state.app.employees,
  (emp) => filterActiveEmployees(emp)
)
export const currentSubscriptionSeats = createSelector(
  (state) => state.subscription?.currentClient,
  (currentClient) => {
    if (!currentClient) {
      return 0
    } else {
      return currentClient?.subscriptionStatus?.webapp?.seats || 0
    }
  }
)
export const getAllActiveEmployees = createSelector(
  (state) => state,
  (state) => {
    return (state.subscription?.listAgentClient || []).reduce((prevValue, currentValue) => {
      // only count active employees when the client is in the clientCaps array
      try {
        if (!currentValue.isUnderAgentSubscription) {
          return prevValue
        }
      } catch (e) {
        return prevValue + currentValue.activeEmployeeNumber
      }
      return prevValue + currentValue.activeEmployeeNumber
    }, 0)
  }
)

export const getMissingEmployees = createSelector(
  // agent's customers who is not registered
  (state) => state.app, // subscription
  activeEmployees,
  (app) =>
    (app.subscriptionStatus?.webapp?.seatsUsedInTheCompany || 0) - (app.subscriptionStatus?.webapp?.remainingSeats || 0)
)

export const selectTaxYear = createSelector(stateApp, (app) => {
  return app?.taxYear || getFiscalYear(new Date())
})

export const selectSelectedTaxYear = createSelector(stateApp, (app) => {
  return app?.selectedTaxYear || getFiscalYear(new Date())
})

export const selectFiscalYears = createSelector(stateApp, (app) => {
  // app is undefined on initial loading
  if (!app) return []
  return app?.allFinancialYears?.map((i) => i - 1)
})

export const selectAllFinancialYears = createSelector(stateApp, (app) => {
  return app?.allFinancialYears ?? []
})

export const selectFilteredPayruns = createSelector(stateApp, (app) => {
  if (!app.taxYear) {
    return app.payRuns || []
  }

  const filteredPayruns = app.payRuns?.filter((payRun) => getFiscalYear(payRun.pd) === app.taxYear)
  return filteredPayruns || []
})

export default appSlice.reducer

import { openPayrunAutomationOptInModal } from '../controllers/business/businessCtrl'
import { sharedSuperCtrl } from './sharedSuperCtrl'
import { setModals } from '../reducers/appSlice'
import { sleep } from '../scheduling/utils'

export async function openPaySuperNow({
  $rootScope,
  $ngRedux,
  Service,
  $state,
  Http,
  Http2,
  $timeout,
  CompanyAction,
  employeeModalService,
  DatepickerService,
  Analytics
}) {
  log.debug('[Step 4] Open the PaySuperNow')

  if ($rootScope.company?.superStream && $rootScope.company?.superStream.connectedDate) {
    Service.modal(
      '',
      [
        '$scope',
        '$uibModalInstance',
        function ($scope, $uibModalInstance) {
          sharedSuperCtrl({
            $scope,
            $rootScope,
            Service,
            $state,
            $ngRedux,
            Http,
            Http2,
            $timeout,
            CompanyAction,
            employeeModalService,
            DatepickerService,
            Analytics,
            $uibModalInstance,
            isModal: true
          })
        }
      ],
      { size: 'lg' },
      'views/business/superStream/superReadyToSubmit.html' // templateUrl
    )
  } else {
    const dontShowPaysuperNowUntil = $rootScope.company?.dontShowPaysuperNowUntil
    const isNewPayrun = $ngRedux.getState().app?.isNewPayrun

    await sleep(0.63) // wait for modal close animation
    if ((!dontShowPaysuperNowUntil || new Date(dontShowPaysuperNowUntil) < new Date()) && isNewPayrun) {
      $ngRedux.dispatch(setModals([{ kind: 'PaySuperNow' }]))
    } else openPayrunAutomationOptInModal()
  }
}

angular
  .module('starter.business.controllers')
  .controller(
    'settingsCtrlV2',
    function ($scope, $rootScope, $window, $ngRedux, $state, RefreshTokenNoDebounce, Logout, Service) {
      $scope.user = {}
      $scope.company = {}
      $scope.iFrameStyle = $rootScope.isOverlayVisible
        ? 'w-full h-full relative z-[999] px-4'
        : 'w-full h-full relative'
      $rootScope.$watch('isOverlayVisible', () => {
        $scope.iFrameStyle = $rootScope.isOverlayVisible
          ? 'w-full h-full relative z-[999] px-4'
          : 'w-full h-full relative'
      })
      $ngRedux.connect((state) => state.app.user || {})($scope.user)
      $ngRedux.connect((state) => state.app.company || {})($scope.company)

      $scope.isLoading = true
      const agentId = $window.localStorage.pr_agent_id
      $scope.$watchGroup(['user.e', 'company.e'], () => {
        if (!$scope.user.e && !$scope.company.e) return
        RefreshTokenNoDebounce((tokenResponse, error) => {
          const errorMessage = error?.data?.message
          if (errorMessage === 'Token Expired') {
            Service.alert('Alert', errorMessage, 'error')
            Logout()
            $state.go('login', {
              redirect: window.location.pathname
            })
          } else {
            const { token, expires } = tokenResponse || {}
            const urlParams = new URLSearchParams(window.location.search)
            const tab = urlParams.get('tab')
            const connectXero = urlParams.get('connectXero')
            const queryParams = new URLSearchParams({
              isIframe: true,
              t: token,
              e: expires,
              userType: agentId ? 'agent' : 'employer',
              clientId: $scope.company._id,
              ...(tab && { tab }),
              ...(connectXero && { connectXero })
            })
            const settingRevampIframe = document.getElementById('settings-v2')
            settingRevampIframe.src = `${process.env.DEV_V2}/settings-page?${queryParams}`
            settingRevampIframe.onload = () => {
              $scope.isLoading = false
              $rootScope.$apply()
            }
          }
        })
      })
    }
  )

import { ngGetInitialData } from '../services'

export let getInitialData = () =>
  console.warn('Empty function - getInitialData: Angular controller is not loaded. (businessCtrl.js)')

angular
  .module('starter.ai-roster.controllers', [])
  .controller(
    'aiRosterCtrl',
    function ($scope, $rootScope, $state, Service, $window, Analytics, $ngRedux, HttpPromiseAndLocalStorage) {
      $ngRedux.connect((state) => {
        if (!state.app?.employees) {
          return {}
        }
        if (!$rootScope.employees) {
          return {}
        }
        return {
          employees: state.app.employees.map((emp) => {
            let root = $rootScope.employees.find((x) => x._id === emp._id)
            return {
              ...root,
              s_r: emp.s_r
            }
          }),
          gbFeatures: state.app?.gbFeatures,
          ...(state.app?.company?.superIncrementStatus && {
            company: {
              ...$rootScope.company,
              superIncrementStatus: {
                ...state.app.company.superIncrementStatus
              }
            }
          }),
          showHelpMenu: state.app?.showHelpMenu,
          isTsrOnly: state.app?.isTsrOnly
        }
      })($rootScope)

      $scope.initialDataLoaded = false
      getInitialData = ngGetInitialData({
        $ngRedux,
        $rootScope,
        Service,
        $window,
        $state,
        Analytics,
        HttpPromiseAndLocalStorage,
        successCallback: () => {
          $scope.initialDataLoaded = true
        }
      })
      $scope.getInitialData = getInitialData

      $scope.getInitialData()
    }
  )

angular
  .module('starter.business.controllers')
  .controller(
    'superCtrlV2',
    function ($scope, $rootScope, $window, $ngRedux, $state, RefreshTokenNoDebounce, Logout, Service) {
      $scope.user = {}
      $scope.company = {}
      $scope.iFrameStyle = $rootScope.isOverlayVisible
        ? 'w-full h-full relative z-modal bg-white'
        : 'w-full h-full relative bg-white'
      $rootScope.$watch('isOverlayVisible', () => {
        $scope.iFrameStyle = $rootScope.isOverlayVisible
          ? 'w-full h-full relative z-modal bg-white'
          : 'w-full h-full relative bg-white'
      })
      $ngRedux.connect((state) => state.app.user || {})($scope.user)
      $ngRedux.connect((state) => state.app.company || {})($scope.company)

      $scope.isLoading = true
      $scope.token = $window.localStorage.pr_token
      const agentId = $window.localStorage.pr_agent_id
      $scope.$watchGroup(['user.e', 'company.e'], () => {
        RefreshTokenNoDebounce((tokenResponse, error) => {
          const errorMessage = error?.data?.message
          if (errorMessage === 'Token Expired') {
            Service.alert('Alert', errorMessage, 'error')
            Logout()
            $state.go('login', {
              redirect: window.location.pathname
            })
          } else {
            const e = $scope.user?.e || $scope.company?.e
            const superStreamRevampIframe = document.getElementById('superstream-v2')
            const queryParams = new URLSearchParams({
              isIframe: true,
              t: $scope.token,
              e,
              userType: agentId ? 'agent' : 'employer',
              clientId: $scope.company._id
            })
            superStreamRevampIframe.src = `${process.env.DEV_V2}/business/superstream?${queryParams}`
            superStreamRevampIframe.onload = () => {
              $scope.isLoading = false
              $rootScope.$apply()
            }
          }
        })
      })
    }
  )

"use strict";
var _a;
exports.__esModule = true;
exports.PAYROLLER_MOBILE_APP_PACKAGE_NAMES = exports.WEB_HEADERS = exports.MOBILE_HEADERS = void 0;
var PAYROLLER_MOBILE_APP_PACKAGE_NAMES = {
    ios: 'payroller.employee.payslip.timetracking.timesheet',
    android: 'com.payroller'
};
exports.PAYROLLER_MOBILE_APP_PACKAGE_NAMES = PAYROLLER_MOBILE_APP_PACKAGE_NAMES;
var MOBILE_HEADERS = (_a = {},
    _a[PAYROLLER_MOBILE_APP_PACKAGE_NAMES.ios] = 'App',
    _a[PAYROLLER_MOBILE_APP_PACKAGE_NAMES.android] = 'App',
    _a['timesheets.employee.scheduling'] = 'TSRApp',
    _a);
exports.MOBILE_HEADERS = MOBILE_HEADERS;
var WEB_HEADERS = {
    payroller: 'Payroller',
    people: 'TSR'
};
exports.WEB_HEADERS = WEB_HEADERS;

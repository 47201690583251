"use strict";
exports.__esModule = true;
exports.roundingOff = void 0;
var addEPS = function (x) {
    return Math.round(((x || 0) + (x < 0 ? -1e-10 : +1e-10)) * 1e9) / 1e9;
};
function roundingOff(value, digits) {
    if (digits === void 0) { digits = 2; }
    var multiplier = Math.pow(10, digits);
    return Math.round(addEPS(addEPS(value) * multiplier)) / multiplier;
}
exports.roundingOff = roundingOff;
